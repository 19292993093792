// Movimientos.js
import React, { useState } from 'react';
import './Movisaldo.css';

const Movimientos = ({ movements }) => {
    const [filter, setFilter] = useState('');
    const [filteredMovements, setFilteredMovements] = useState(movements);

    const handleFilterChange = (event) => {
        const value = event.target.value.toLowerCase();
        setFilter(value);
        setFilteredMovements(
            movements.filter(
                (movement) =>
                    movement.type.toLowerCase().includes(value) ||
                    movement.date.toLowerCase().includes(value)
            )
        );
    };

    return (
        <div className="movements-page">
            <h2>Historial de Movimientos</h2>
            <div className="filter-container">
                <input
                    type="text"
                    placeholder="Filtrar por tipo o fecha..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="filter-input"
                />
            </div>
            <ul className="movements-list">
                {filteredMovements.length === 0 ? (
                    <p>No hay movimientos que coincidan con el filtro.</p>
                ) : (
                    filteredMovements.map((movement, index) => (
                        <li key={index} className="movement-item">
                            <span className="movement-type">{movement.type}</span>
                            <span className="movement-amount">{movement.amount}$</span>
                            <span className="movement-date">{movement.date}</span>
                        </li>
                    ))
                )}
            </ul>
        </div>
    );
};

export default Movimientos;

import React, { useState } from 'react';
import './Header.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Header = ({ toggleSidebar, user, logout }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const initials = user
        ? user.split(' ').map(name => name.charAt(0).toUpperCase()).join('')
        : '';

    return (
        <div className="header">
            <button className="toggle-sidebar" onClick={toggleSidebar}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="4" width="24" height="2" fill="#7C64FF"/>
                    <rect y="11" width="24" height="2" fill="#7C64FF"/>
                    <rect y="18" width="24" height="2" fill="#7C64FF"/>
                </svg>
            </button>
            <div className="logo"><span>Minatechk</span>
               
            </div>
            <div className="search-bar">
                <input type="text" placeholder="Search" />
            </div>
            <div className="notifications">
                <svg style={{ marginRight: '5px' }} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="#7C64FF">
                    <path d="M0 0h24v24H0V0z" fill="none"/>
                    <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6V9c0-3.07-1.63-5.64-4.5-6.32V2c0-.83-.67-1.5-1.5-1.5S10 1.17 10 2v.68C7.13 3.36 5.5 5.92 5.5 9v7l-1.7 1.7c-.14.14-.3.33-.3.6v.2c0 .55.45 1 1 1h15c.55 0 1-.45 1-1v-.2c0-.27-.16-.46-.3-.6L18 16zm-2 1H8v-8c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v8z"/>
                </svg>
                <span className="notification-count">1</span>
            </div>
            <div className="user-profile">
                <div className="user-avatar">{initials}</div>
                <div className="user-info">
                    <span>{user}</span>
                    <button className="dropdown-button" onClick={toggleDropdown}>
                        <ArrowDropDownIcon className={`dropdown-arrow ${dropdownVisible ? 'open' : ''}`} />
                    </button>
                </div>
                {dropdownVisible && (
                    <div className="dropdown-menu">
                        <a href="#account">Cuenta</a>
                        <a href="#logout" onClick={logout}>Cerrar sesión</a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;

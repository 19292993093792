import React, { useState } from 'react';
import './login.css';

const Register = ({ toggleLogin, handleRegister }) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleRegisterSubmit = (e) => {
        e.preventDefault();
        // Validación simple para asegurar que las contraseñas coincidan
        if (password !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }
        // Llamar a la función de registro pasada como prop
        handleRegister(username, email, password);
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h2>Register</h2>
                <form onSubmit={handleRegisterSubmit}>
                    <div className="input-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="login-button">Register</button>
                </form>
                <p className="login-prompt">
                    ¿Ya tienes cuenta? <span className="login-link" onClick={toggleLogin}>Inicia sesión</span>
                </p>
            </div>
        </div>
    );
};

export default Register;

import React, { useState } from 'react';
import './login.css';

const Login = ({ setUser, toggleRegister }) => {
    const [usernameOrEmail, setUsernameOrEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí podrías agregar la lógica para el inicio de sesión
        console.log('Username or Email:', usernameOrEmail);
        console.log('Password:', password);
        setUser(usernameOrEmail, password); // Establecer el usuario logueado
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label htmlFor="usernameOrEmail">Username or Email</label>
                        <input
                            type="text"
                            id="usernameOrEmail"
                            name="usernameOrEmail"
                            value={usernameOrEmail}
                            onChange={(e) => setUsernameOrEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="login-button">Login</button>
                </form>
                <p className="register-prompt">
                    ¿Aún no tienes cuenta? <span className="register-link" onClick={toggleRegister}>Crea una</span>
                </p>
            </div>
        </div>
    );
};

export default Login;

import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import Sidebar from './sidebar';
import Header from './header';
import Login from './Login';
import Register from './registro';
import Movisaldo from './Movisaldo';
import Soporte from './soporte'; // Asumiendo que tienes un componente de soporte
import './App.css';

const App = () => {
    const [user, setUser] = useState(null);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [depositAmount, setDepositAmount] = useState('');
    const [balance, setBalance] = useState(0); // Inicializar balance en 0
    const [movements, setMovements] = useState([]);
    const [registerView, setRegisterView] = useState(false);
    const [registeredUsers, setRegisteredUsers] = useState([]);
    const navigate = useNavigate();
    const [transactionId, setTransactionId] = useState(1); // Para ID de transacción

    const showSuccessModal = (amount) => {
        setModalContent(
            <div className="modal-content bounce success">
                <div className="modal-header">Depósito Exitoso</div>
                <div className="success-icon">✔️</div>
                <p>Se han depositado {amount}$ en tu cuenta.</p>
                <button className="modal-button" onClick={() => {
                    setIsModalOpen(false);
                    navigate('/'); // Redirigir al dashboard
                }}>
                    Cerrar
                </button>
            </div>
        );
        setIsModalOpen(true);
    };

    const handleWompiSuccess = useCallback((amount) => {
        const formattedAmount = parseFloat(amount.replace(/,/g, ''));
        // Verificar si el pago ya fue registrado
        const existingTransaction = movements.find(movement => movement.amount === formattedAmount);
        
        if (!existingTransaction && !isNaN(formattedAmount) && formattedAmount > 0) {
            setBalance(prevBalance => prevBalance + formattedAmount);
            setMovements(prevMovements => [
                ...prevMovements,
                { id: transactionId, type: 'Depósito', amount: formattedAmount, date: new Date().toLocaleString() }
            ]);
            setTransactionId(prevId => prevId + 1); // Incrementar ID de transacción
            showSuccessModal(formattedAmount);
        } else {
            alert('El monto ya fue depositado anteriormente o es inválido.');
        }
    }, [movements, transactionId]);

    useEffect(() => {
        const savedUser = localStorage.getItem('user');
        if (savedUser) {
            setUser(JSON.parse(savedUser));
        }

        const urlParams = new URLSearchParams(window.location.search);
        const transactionStatus = urlParams.get('status');
        const amount = urlParams.get('amount');
        
        if (transactionStatus === 'APPROVED' && amount) {
            handleWompiSuccess(amount);
        }
    }, [handleWompiSuccess]);

    const formatNumber = (value) => {
        return value.replace(/[^\d]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const handleInputChange = (event) => {
        const { value } = event.target;
        const formattedValue = formatNumber(value);
        setDepositAmount(formattedValue);
    };

    const handleRegister = (username, email, password) => {
        const newUser = { username, email, password };
        setRegisteredUsers([...registeredUsers, newUser]);
        setRegisterView(false);
    };

    const handleLogin = (usernameOrEmail, password) => {
        const foundUser = registeredUsers.find(
            (user) => (user.username === usernameOrEmail || user.email === usernameOrEmail) && user.password === password
        );
        if (foundUser) {
            setUser(usernameOrEmail);
            localStorage.setItem('user', JSON.stringify(usernameOrEmail));
        } else {
            alert('El nombre de usuario, correo electrónico o contraseña es incorrecto.');
        }
    };

    const toggleSidebar = () => {
        setSidebarActive(!sidebarActive);
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem('user');
    };

    const handleModalBackgroundClick = (e) => {
        if (e.target.classList.contains('modal')) {
            setIsModalOpen(false);
        }
    };

    const handleDepositClick = () => {
        setModalContent(
            <div className="modal-content bounce">
                <div className="modal-header">Selecciona el método de depósito</div>
                <div className="modal-actions">
                    <button className="modal-button" onClick={handleDepositWithQR}>Depositar con QR</button>
                    <button className="modal-button" onClick={handleDepositWithCard}>Depositar con Tarjeta Débito/Crédito</button>
                </div>
            </div>
        );
        setIsModalOpen(true);
    };

    const handleDepositWithQR = () => {
        setModalContent(
            <div className="modal-content bounce">
                <div className="modal-header">Depositar con QR</div>
                <div className="input-group">
                    <label htmlFor="deposit-amount-qr" style={{ fontSize: '20px' }}>Monto a depositar:</label>
                    <input 
                        type="text" 
                        id="depositAmountQR" 
                        className="styled-input"
                        placeholder="Monto a depositar" 
                        value={depositAmount} 
                        onChange={handleInputChange} 
                    />
                    <div className="qr-code">
                        <img src="https://via.placeholder.com/150" alt="QR Code" />
                    </div>
                </div>
                <div className="modal-actions">
                    <button className="modal-button" onClick={() => handleWompiSuccess(depositAmount)}>Confirmar Depósito</button>
                </div>
            </div>
        );
        setIsModalOpen(true);
    };

    const handleDepositWithCard = () => {
        const uniqueReference = `ref-${new Date().getTime()}`; // Generar referencia única
        setModalContent(
            <div className="modal-content bounce">
                <div className="modal-header">Depositar con Tarjeta Débito/Crédito</div>
                <div className="input-group deposit">
                    <label htmlFor="deposit-amount-card" style={{ fontSize: '20px' }}>Monto a depositar:</label>
                    <input 
                        type="text" 
                        id="depositAmountCard" 
                        className="styled-input"
                        placeholder="Monto a depositar" 
                        value={depositAmount} 
                        onChange={handleInputChange} 
                    />
                </div>
                <div className="modal-actions">
                    <form
                        action="https://checkout.wompi.co/p/"
                        method="GET"
                    >
                        <input type="hidden" name="public-key" value="pub_test_BsTpifwBxRS4DhnjmdW4pVOybuf67uLC" />
                        <input type="hidden" name="currency" value="COP" />
                        <input type="hidden" name="amount-in-cents" value={parseFloat(depositAmount.replace(/,/g, '')) * 100} />
                        <input type="hidden" name="reference" value={uniqueReference} /> {/* Aquí se establece la referencia única */}
                        <input type="hidden" name="redirect-url" value={`${window.location.origin}?status=APPROVED&amount=${depositAmount}`} />
                        <button className='wompi' type="submit">Depositar</button>
                    </form>
                </div>
            </div>
        );
        setIsModalOpen(true);
    };

    const handleWithdrawClick = () => {
        setModalContent(
            <div className="modal-content bounce">
                <div className="modal-header">Selecciona el método de retiro</div>
                <div className="modal-actions">
                    <button className="modal-button" onClick={handleWithdrawWallet}>Retirar con Wallet</button>
                    <button className="modal-button" onClick={handleWithdrawBank}>Retirar con Cuenta Bancaria</button>
                </div>
            </div>
        );
        setIsModalOpen(true);
    };

    const handleWithdrawWallet = () => {
        setModalContent(
            <div className="modal-content bounce">
                <div className="modal-header">Retirar con Wallet</div>
                <div className="input-group">
                    <input type="text" id="account-name" placeholder="Titular de la cuenta" />
                    <select id="wallet-type">
                        <option>Seleccionar wallet</option>
                        <option>Wallet 1</option>
                        <option>Wallet 2</option>
                    </select>
                    <input type="text" id="wallet-address" placeholder="Dirección de la wallet" />
                    <input type="text" id="network" placeholder="Red TRON (TRC20)" />
                    <input 
                        type="number" 
                        id="withdraw-amount" 
                        placeholder="Monto" 
                        min="1" 
                        step="1" 
                        className="styled-input"
                    />
                </div>
                <div className="modal-actions">
                    <button className="modal-button" onClick={handleSuccess}>Enviar Solicitud</button>
                </div>
            </div>
        );
        setIsModalOpen(true);
    };

    const handleWithdrawBank = () => {
        setModalContent(
            <div className="modal-content bounce">
                <div className="modal-header">Retirar con Cuenta Bancaria</div>
                <div className="input-group">
                    <input type="text" id="account-name" placeholder="Titular de la cuenta" />
                    <select id="bank-type">
                        <option>Seleccionar banco</option>
                        <option>Banco 1</option>
                        <option>Banco 2</option>
                    </select>
                    <input type="text" id="account-number" placeholder="Número de cuenta" />
                    <input type="text" id="iban-code" placeholder="Código IBAN" />
                    <input 
                        type="number" 
                        id="withdraw-amount" 
                        placeholder="Monto" 
                        min="1" 
                        step="1" 
                        className="styled-input"
                    />
                </div>
                <div className="modal-actions">
                    <button className="modal-button" onClick={handleSuccess}>Enviar Solicitud</button>
                </div>
            </div>
        );
        setIsModalOpen(true);
    };

    const handleSuccess = () => {
        setModalContent(
            <div className="modal-content bounce success">
                <div className="modal-header">Solicitud Exitosa</div>
                <div className="success-icon">✔️</div>
            </div>
        );
        setTimeout(() => setIsModalOpen(false), 2000);
    };

    if (!user) {
        return (
            <div className="auth-container">
                {registerView ? (
                    <Register toggleLogin={() => setRegisterView(false)} handleRegister={handleRegister} />
                ) : (
                    <Login setUser={handleLogin} toggleRegister={() => setRegisterView(true)} />
                )}
            </div>
        );
    }

    return (
        <div className="app-container">
            <Header toggleSidebar={toggleSidebar} user={user} logout={logout} />
            <div className="content-wrapper">
                <Sidebar isActive={sidebarActive} />
                <div className="main-content">
                    <Routes>
                        <Route path="/" element={
                            <div className="dashboard-grid">
                                <div className="grid-item balance">
                                    <h3>Balance</h3>
                                    <div className="balance-box">
                                        <div className="balance-amount">{balance}$</div>
                                        <div className="balance-actions">
                                            <button className="balance-button" onClick={handleDepositClick}>Depositar</button>
                                            <button className="balance-button" onClick={handleWithdrawClick}>Retirar</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item last-deposit">
                                    <h3>Último depósito</h3>
                                    <div className="last-deposit-box">
                                        <div className="last-deposit-amount">
                                            {movements.length > 0 ? movements[movements.length - 1].amount + '$' : 'No hay depósitos'}
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item movements">
                                    <h3>Movimientos</h3>
                                    <div className="movements-box">
                                        {movements.slice(-3).reverse().map((movement, index) => (
                                            <div key={index} className="movement-item">
                                                {movement.type}: {movement.amount}$ - {movement.date} (ID: {movement.id})
                                            </div>
                                        ))}
                                    </div>
                                    {movements.length > 3 && (
                                        <Link to="/Movisaldo" className="link-button">Ver más movimientos</Link>
                                    )}
                                </div>
                                <div className="grid-item affiliates">
                                    <h3>Afiliados</h3>
                                    <div className="affiliate-box">
                                        <div className="affiliate-circle"></div>
                                        <div className="affiliate-circle"></div>
                                        <div className="affiliate-circle"></div>
                                        <div className="affiliate-circle"></div>
                                        <div className="affiliate-circle"></div>
                                    </div>
                                </div>
                            </div>
                        } />
                        <Route path="/Movisaldo" element={<Movisaldo movements={movements} />} />
                        <Route path="/soporte" element={<Soporte />} />
                    </Routes>
                </div>
            </div>
            {isModalOpen && (
                <div className="modal active" onClick={handleModalBackgroundClick}>
                    {modalContent}
                </div>
            )}
        </div>
    );
};

export default App;

import React from 'react';
import './Sidebar.css';
import './Movisaldo.js';
import './App.js';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SupportIcon from '@mui/icons-material/Support';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';

const Sidebar = ({ isActive }) => {
    return (
        <div className={`sidebar ${isActive ? 'active' : ''}`}>
            <a href="App.js" className="active">
                <DashboardIcon className="icon-placeholder" />
                <span>Dashboard</span>
            </a>
            <a href="Movisaldo.js">
                <TrendingUpIcon className="icon-placeholder" />
                <span>Movimientos</span>
            </a>
            <a href="#soporte">
                <SupportIcon className="icon-placeholder" />
                <span>Soporte</span>
            </a>
            <a href="#afiliados">
                <GroupIcon className="icon-placeholder" />
                <span>Afiliados</span>
            </a>
            <a href="#settings" className="settings">
                <SettingsIcon className="icon-placeholder" />
                <span>Ajustes</span>
            </a>
        </div>
    );
};

export default Sidebar;